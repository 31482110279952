import {
  useCallback,
  useEffect,
} from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  activateTutorial,
  completeTutorial,
  loadCompletedTutorials,
  updateTutorial,
  cancelTutorial,
  disableTutorial,
} from '../actions/tutorials';

const getLocalStorage = () => {
  try {
    const obj = window.localStorage;
    if ([obj, obj.getItem, obj.setItem].some((v) => !v)) return null;
    return obj;
  } catch {
    return null;
  }
};

const useTutorial = (tutorial) => {
  const {
    activeTutorial,
    activeTutorialData,
    completedTutorials,
    disabledTutorials,
  } = useSelector((state) => state?.tutorials, shallowEqual);
  const disabled = Boolean(disabledTutorials[tutorial]);

  const dispatch = useDispatch();

  const activate = useCallback((tutorialData) => {
    if (disabled) return;
    dispatch(activateTutorial(tutorial, tutorialData));
  }, [disabled, dispatch, tutorial]);
  const update = useCallback((tutorialData) => {
    dispatch(updateTutorial(tutorial, tutorialData));
  }, [dispatch, tutorial]);
  const complete = useCallback(() => {
    dispatch(completeTutorial(tutorial));
  }, [dispatch, tutorial]);
  const cancel = useCallback(() => {
    dispatch(cancelTutorial(tutorial));
  }, [dispatch, tutorial]);

  useEffect(() => {
    if (!getLocalStorage() && !disabled) {
      dispatch(disableTutorial(tutorial));
    }
  }, [disabled, dispatch, tutorial]);

  useEffect(() => {
    const localStorage = getLocalStorage();
    if (!localStorage) return;
    const completedHistory = localStorage.getItem('completedTutorials');
    if (!completedHistory) return;
    const parsed = Object.fromEntries(
      Object.entries(JSON.parse(completedHistory))
        .map(([key, dateString]) => [key, new Date(Date.parse(dateString))]),
    );
    dispatch(loadCompletedTutorials(parsed));
  }, [dispatch, tutorial]);

  useEffect(() => {
    const localStorage = getLocalStorage();
    if (!localStorage) return;
    localStorage.setItem('completedTutorials', JSON.stringify(completedTutorials));
  }, [completedTutorials]);

  return {
    state: {
      isActive: activeTutorial === tutorial,
      data: activeTutorial === tutorial ? activeTutorialData : {},
      completedAt: completedTutorials[tutorial],
      disabled,
    },
    activate,
    update,
    complete,
    cancel,
  };
};

export default useTutorial;
