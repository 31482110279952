import LazyLoad from 'react-lazyload';

const ConditionalLazyLoad = (props) => {
    const {
        children,
        shouldLazyLoad = false,
        ...otherProps
    } = props;

    return !shouldLazyLoad ? children : <LazyLoad {...otherProps}>{children}</LazyLoad>;
}

export default ConditionalLazyLoad;