import React, { forwardRef, useCallback } from 'react';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';

const ResponsiveIconButton = forwardRef((props, ref) => {
  const {
    breakpoint = 'sm',
    icon,
    sx = {},
    ...buttonProps
  } = props;

  const smartRef = useCallback((node) => {
    if (!ref || !node) return;
    if (getComputedStyle(node).display !== 'none') {
      // eslint-disable-next-line no-param-reassign
      ref.current = node;
    }
  }, [ref]);

  return (
    <>
      <Button
        ref={smartRef}
        {...buttonProps}
        sx={{
          ...sx,
          display: breakpoint === 'disabled'
            ? undefined
            : { xs: 'none', [breakpoint]: 'inline-flex' },
        }}
      >
        {props.children}
      </Button>
      <IconButton
        ref={smartRef}
        {...buttonProps}
        sx={{
          ...sx,
          display: breakpoint === 'disabled'
            ? 'none'
            : { xs: 'inline-flex', [breakpoint]: 'none' },
        }}
      >
        {icon}
      </IconButton>
    </>
  );
});

export default ResponsiveIconButton;
