import { useCallback, useRef, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

// for some reason, the authors of react-intersection-observer
// decided they did not want to return a real ref, and instead
// return a "callback ref", which is just a function.

const useRefInView = (options) => {
  const extraRef = useRef();
  const inViewHook = useInView(options);
  const { ref: updateInViewHook, ...hookOutput } = inViewHook;

  const setRef = useCallback((node) => {
    updateInViewHook(node);
    extraRef.current = node;
  }, [updateInViewHook]);

  return useMemo(() => ({
    ...hookOutput,
    ref: {
      get current() {
        return extraRef.current;
      },
      set current(node) {
        setRef(node);
      },
    },
  }), [hookOutput, setRef]);
};

export default useRefInView;
